import React, { useEffect, useState } from 'react';
import { createStyles, Grid, Container, makeStyles, Theme } from '@material-ui/core';
import EText from '../Components/EText';
import { useLayout } from '../Layout/LayoutContext';
import { ShippingPaymentConfiguration } from '@magistrmartin/eshop-frontend-shared';
import { separateThousands } from '../Utils/Common';
import Environment from '../Environments';
import { imagesService } from '../Utils/ApiService';
import DeliveryPaymentCard from '../Components/DeliveryPaymentCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    smallRadioButton: {
      '& svg': {
        width: '0.8em',
        height: '0.8em',
      },
    },
    column: {
      margin: 8,
    },
    size: {
      fontSize: '1rem',
    },

    gridWrap: {
      display: 'flex',
      border: '1px solid #C93666',
      borderRadius: 14,
      marginBottom: 10,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 25,
      },

      overflow: 'hidden',
    },

    panelContainer: {
      borderRadius: 8,
      border: '1px solid #DBE1EB',
    },
    delivery: {
      display: 'flex',
      alignItems: 'center',
      gap: 12,
    },
    priceTable: {
      float: 'right',
    },

    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    panelItemSelected: {
      backgroundColor: 'rgb(132, 181, 23)',
      color: 'white',
      borderRadius: '50%',
      width: 32,
      height: 32,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      bottom: -12,
      right: -12,
    },
  })
);

export default function DeliveryAndPayment() {
  const classes = useStyles();
  const layout = useLayout();
  const [deliveryPaymentConfig, setDeliveryPaymentConfig] = useState<ShippingPaymentConfiguration | undefined>(
    undefined
  );

  useEffect(
    () => {
      imagesService.get(
        '/text',
        {
          file: Environment.domain.replace('.', '_') + '_shippingPayment.json',
          location: 'config',
        },
        {
          success: setDeliveryPaymentConfig,
          error: () => layout.error('Při načítání cen dopravy a platby došlo k chyně'),
        }
      );
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <Container maxWidth="lg">
        <Grid container justifyContent="center">
          <Grid item xs={12} md={12} className={classes.column}>
            <EText variant="h4" bold>
              Doprava a platba
            </EText>
            <EText>
              {Environment.fullName} si pro Vás připravil přehledný výber možnostní dopravy a platby za rozumné ceny.
            </EText>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} className={`${classes.column} ${classes.delivery}`}>
          <img src="https://api.magistrmartin.cz/images/noauth/image/images/delivery.svg" alt="delivery" width="48px" />
          <EText variant="h5" bold>
            Doprava
          </EText>
        </Grid>

        {/*REZERVACE LÉKÁRNA - POUZE ADAM */}
        {process.env.REACT_APP_ESHOP_VARIANT === 'martin' ? (
          <Grid item xs={12} md={12} className={`${classes.column} ${classes.gridWrap}`}>
            <DeliveryPaymentCard
              description={deliveryPaymentConfig?.shippingPrices.personalPickup.description || ''}
              iconUrl={deliveryPaymentConfig?.shippingPrices.personalPickup.image || ''}
              label={deliveryPaymentConfig?.shippingPrices.personalPickup.label || ''}
              imgPath="https://api.magistrmartin.cz/images/noauth/image/images/clock.svg"
              captionText="Aktuální rychlost doručení"
              timeEstimate="od 60 minut od vychystáni"
              price={separateThousands(deliveryPaymentConfig?.shippingPrices.personalPickup.price) + ' Kč' || ''}
              priceAddon={
                'od ' +
                  separateThousands(deliveryPaymentConfig?.shippingPrices.personalPickup.freeFrom) +
                  ' Kč ZDARMA' || ''
              }
              mmPlusText={
                process.env.REACT_APP_ESHOP_VARIANT === 'martin'
                  ? 'Přihlašte se do klubu Magistr Martin a od ' +
                    separateThousands(deliveryPaymentConfig?.shippingPrices.packeta.freeFromRegistered) +
                    ' Kč získáte dopravu' +
                    (deliveryPaymentConfig?.shippingPrices.personalPickup.priceRegistered === 0
                      ? ' ZDARMA'
                      : ' za ' +
                        separateThousands(deliveryPaymentConfig?.shippingPrices.personalPickup.priceRegistered) +
                        ' Kč')
                  : undefined
              }
            />
          </Grid>
        ) : null}

        {/*OSOBNÍ ODBĚR - ZÁSILOKOVNA*/}
        <Grid item xs={12} md={12} className={`${classes.column} ${classes.gridWrap}`}>
          <DeliveryPaymentCard
            description={deliveryPaymentConfig?.shippingPrices.packeta.description || ''}
            iconUrl={deliveryPaymentConfig?.shippingPrices.packeta.image || ''}
            label={deliveryPaymentConfig?.shippingPrices.packeta.label || ''}
            imgPath="https://api.magistrmartin.cz/images/noauth/image/images/clock.svg"
            captionText="Aktuální rychlost doručení"
            timeEstimate="1-2 dny od odeslání"
            price={separateThousands(deliveryPaymentConfig?.shippingPrices.packeta.price) + ' Kč' || ''}
            priceAddon={
              'od ' + separateThousands(deliveryPaymentConfig?.shippingPrices.packeta.freeFrom) + ' Kč ZDARMA' || ''
            }
            mmPlusText={
              process.env.REACT_APP_ESHOP_VARIANT === 'martin'
                ? 'Přihlašte se do klubu Magistr Martin a od ' +
                  separateThousands(deliveryPaymentConfig?.shippingPrices.packeta.freeFromRegistered) +
                  ' Kč získáte dopravu' +
                  (deliveryPaymentConfig?.shippingPrices.packeta.priceRegistered === 0
                    ? ' ZDARMA'
                    : ' za ' + separateThousands(deliveryPaymentConfig?.shippingPrices.packeta.priceRegistered) + ' Kč')
                : undefined
            }
          />
        </Grid>

        {/*DORUČENÍ AŽ DOMŮ*/}
        <Grid item xs={12} md={12} className={`${classes.column} ${classes.gridWrap}`}>
          <DeliveryPaymentCard
            description={deliveryPaymentConfig?.shippingPrices.packetaHome.description || ''}
            iconUrl={deliveryPaymentConfig?.shippingPrices.packetaHome.image || ''}
            label={deliveryPaymentConfig?.shippingPrices.packetaHome.label || ''}
            imgPath="https://api.magistrmartin.cz/images/noauth/image/images/clock.svg"
            captionText="Aktuální rychlost doručení"
            timeEstimate="1-2 dny od odeslání"
            price={separateThousands(deliveryPaymentConfig?.shippingPrices.packetaHome.price) + ' Kč' || ''}
            priceAddon={
              'od ' + separateThousands(deliveryPaymentConfig?.shippingPrices.packetaHome.freeFrom) + ' Kč ZDARMA' || ''
            }
            mmPlusText={
              process.env.REACT_APP_ESHOP_VARIANT === 'martin'
                ? 'Přihlašte se do klubu Magistr Martin a od ' +
                  separateThousands(deliveryPaymentConfig?.shippingPrices.packetaHome.freeFromRegistered) +
                  ' Kč získáte dopravu' +
                  (deliveryPaymentConfig?.shippingPrices.packetaHome.priceRegistered === 0
                    ? ' ZDARMA'
                    : ' za ' +
                      separateThousands(deliveryPaymentConfig?.shippingPrices.packetaHome.priceRegistered) +
                      ' Kč')
                : undefined
            }
          />
        </Grid>

        <br />
        <br />

        <Grid item xs={12} md={12} className={`${classes.column} ${classes.delivery}`}>
          <img src="https://api.magistrmartin.cz/images/noauth/image/images/payment.svg" alt="delivery" width="48px" />
          <EText variant="h5" bold>
            Platba
          </EText>
        </Grid>

        {/*KLASICKÝ BANKOVNÍ PŘEVOD*/}
        <Grid item xs={12} md={12} className={`${classes.column} ${classes.gridWrap}`}>
          <DeliveryPaymentCard
            description={deliveryPaymentConfig?.paymentPrices.bankTransactionEmail.description || ''}
            iconUrl={deliveryPaymentConfig?.paymentPrices.bankTransactionEmail.image || ''}
            label={deliveryPaymentConfig?.paymentPrices.bankTransactionEmail.label || ''}
            imgPath="https://api.magistrmartin.cz/images/noauth/image/images/delivery2.svg"
            captionText="Platí pro dopravu"
            paymentText={['Osobní odběr - Zásilkovna', 'a Doručení až domů']}
            isPayment={true}
            imgFooterPath={[
              ['https://api.magistrmartin.cz/images/noauth/image/images/1692782258625ab.webp', '80', '40'],
              ['https://api.magistrmartin.cz/images/noauth/image/images/1692782272977csas.webp', '80', '40'],
              ['https://api.magistrmartin.cz/images/noauth/image/images/1692782285316csob.webp', '80', '40'],
              ['https://api.magistrmartin.cz/images/noauth/image/images/1692782299446fb_new.webp', '80', '40'],
              ['https://api.magistrmartin.cz/images/noauth/image/images/1692782311180kb.webp', '80', '40'],
              ['https://api.magistrmartin.cz/images/noauth/image/images/1692782330670mb.webp', '80', '40'],
              ['https://api.magistrmartin.cz/images/noauth/image/images/1692782345721mo.webp', '80', '40'],
              ['https://api.magistrmartin.cz/images/noauth/image/images/1692782357595rb.webp', '80', '40'],
              ['https://api.magistrmartin.cz/images/noauth/image/images/1692782372910UniCredit.webp', '80', '40'],
            ]}
            price={
              deliveryPaymentConfig?.paymentPrices.bankTransaction.price === 0
                ? 'ZDARMA'
                : separateThousands(deliveryPaymentConfig?.paymentPrices.bankTransaction.price) + ' Kč'
            }
          />
        </Grid>

        {/*BANKOVNÍ PŘEVOD*/}
        <Grid item xs={12} md={12} className={`${classes.column} ${classes.gridWrap}`}>
          <DeliveryPaymentCard
            description={deliveryPaymentConfig?.paymentPrices.bankTransaction.description || ''}
            iconUrl={deliveryPaymentConfig?.paymentPrices.bankTransaction.image || ''}
            label={deliveryPaymentConfig?.paymentPrices.bankTransaction.label || ''}
            imgPath="https://api.magistrmartin.cz/images/noauth/image/images/delivery2.svg"
            captionText="Platí pro dopravu"
            paymentText={['Osobní odběr - Zásilkovna', 'a Doručení až domů']}
            isPayment={true}
            imgFooterPath={[
              ['https://api.magistrmartin.cz/images/noauth/image/images/1692782258625ab.webp', '80', '40'],
              ['https://api.magistrmartin.cz/images/noauth/image/images/1692782272977csas.webp', '80', '40'],
              ['https://api.magistrmartin.cz/images/noauth/image/images/1692782285316csob.webp', '80', '40'],
              ['https://api.magistrmartin.cz/images/noauth/image/images/1692782299446fb_new.webp', '80', '40'],
              ['https://api.magistrmartin.cz/images/noauth/image/images/1692782311180kb.webp', '80', '40'],
              ['https://api.magistrmartin.cz/images/noauth/image/images/1692782330670mb.webp', '80', '40'],
              ['https://api.magistrmartin.cz/images/noauth/image/images/1692782345721mo.webp', '80', '40'],
              ['https://api.magistrmartin.cz/images/noauth/image/images/1692782357595rb.webp', '80', '40'],
              ['https://api.magistrmartin.cz/images/noauth/image/images/1692782372910UniCredit.webp', '80', '40'],
            ]}
            price={
              deliveryPaymentConfig?.paymentPrices.bankTransaction.price === 0
                ? 'ZDARMA'
                : separateThousands(deliveryPaymentConfig?.paymentPrices.bankTransaction.price) + ' Kč'
            }
          />
        </Grid>
        {/*KARTOU ONLINE*/}
        <Grid item xs={12} md={12} className={`${classes.column} ${classes.gridWrap}`}>
          <DeliveryPaymentCard
            description={deliveryPaymentConfig?.paymentPrices.paymentGate.description || ''}
            iconUrl={deliveryPaymentConfig?.paymentPrices.paymentGate.image || ''}
            label={deliveryPaymentConfig?.paymentPrices.paymentGate.label || ''}
            imgPath="https://api.magistrmartin.cz/images/noauth/image/images/delivery2.svg"
            captionText="Platí pro dopravu"
            paymentText={['Osobní odběr - Zásilkovna', 'a Doručení až domů']}
            isPayment={true}
            imgFooterPath={[
              ['https://api.magistrmartin.cz/images/noauth/image/images/1692781874505logo-h.webp', '98', '24'],
              [
                'https://api.magistrmartin.cz/images/noauth/image/images/1692781905269Visa_Brandmark_Blue_RGB_2021.webp',
                '53',
                '18',
              ],
              [
                'https://api.magistrmartin.cz/images/noauth/image/images/1692781918566mc_symbol_opt_73_3x.webp',
                '44',
                '32',
              ],
              [
                'https://api.magistrmartin.cz/images/noauth/image/images/1692781945456GPay_Acceptance_Mark_800(1).webp',
                '45',
                '24',
              ],
              [
                'https://api.magistrmartin.cz/images/noauth/image/images/1692781930683logoapplepay(1)(1).webp',
                '42',
                '32',
              ],
            ]}
            price={
              deliveryPaymentConfig?.paymentPrices.paymentGate.price === 0
                ? 'ZDARMA'
                : separateThousands(deliveryPaymentConfig?.paymentPrices.paymentGate.price) + ' Kč'
            }
          />
        </Grid>
        {/*DOBÍRKA - HOTOVĚ NEBO KARTOU*/}
        <Grid item xs={12} md={12} className={`${classes.column} ${classes.gridWrap}`}>
          <DeliveryPaymentCard
            description={deliveryPaymentConfig?.paymentPrices.pickup.description || ''}
            iconUrl={deliveryPaymentConfig?.paymentPrices.pickup.image || ''}
            label={deliveryPaymentConfig?.paymentPrices.pickup.label || ''}
            imgPath="https://api.magistrmartin.cz/images/noauth/image/images/delivery2.svg"
            captionText="Platí pro dopravu"
            paymentText={['Rezervace Lékárna', 'Osobní odběr - Zásilkovna', 'a Doručení až domů']}
            isPayment={true}
            price={
              deliveryPaymentConfig?.paymentPrices.pickup.price === 0
                ? 'ZDARMA'
                : separateThousands(deliveryPaymentConfig?.paymentPrices.pickup.price) + ' Kč'
            }
          />
        </Grid>

        <br />
        <br />

        <Grid container>
          <Grid item xs={4} className={`${classes.column}`}>
            <EText variant="h5" bold>
              Provozovatel platební brány
            </EText>
            <br />
            <EText bold>ComGate Payments, a.s.</EText>
            <EText>Gočárova třída 1754 / 48b</EText>
            <EText>500 02 Hradec Králové</EText>
            <EText>IČ: 279 24 505, DIČ: CZ27924505</EText>
            <br />
            <EText>Volejte nám v pracovní dny 8:00 až 20:00.</EText>
            <EText>Telefon: +420 228 224 267</EText>
            <EText>E-mail: platby-podpora@comgate.cz</EText>
            <br />
            <EText>
              Web:{' '}
              <a style={{ textDecoration: 'none' }} href="https://www.comgate.cz/cz/platebni-brana">
                https://www.comgate.cz/cz/platebni-brana
              </a>
            </EText>
          </Grid>
          <Grid item xs={7} className={`${classes.column}`}>
            <EText variant="h5" bold>
              Informace o platebních metodách:
            </EText>
            <br />
            <EText>
              Platba kartou:{' '}
              <a style={{ textDecoration: 'none' }} href="https://help.comgate.cz/v1/docs/cs/platby-kartou">
                https://help.comgate.cz/v1/docs/cs/platby-kartou
              </a>
            </EText>
            <EText>
              Platba bankovním převodem:{' '}
              <a style={{ textDecoration: 'none' }} href="https://help.comgate.cz/docs/bankovni-prevody">
                https://help.comgate.cz/docs/bankovni-prevody
              </a>
            </EText>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
