import React from 'react';
import {
  createStyles,
  Grid,
  makeStyles,
  Table,
  Checkbox,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Hidden,
} from '@material-ui/core';
import { deliveryType, packetaInfo, payment } from './FinnishOrder';
import EText from '../../Components/EText';
import { IProduct, ShippingPaymentConfiguration, IProfile } from '@magistrmartin/eshop-frontend-shared';
import { getPrice } from '../../Utils/Product';
import { DiscountsStatus } from '../../Utils/Discounts';
import Gifts from './Gifts';
import { formatPhoneNumber, separateThousands } from '../../Utils/Common';
import { useLayout } from '../../Layout/LayoutContext';
import Environment from '../../Environments';
import CartItem from '../../Types/cartItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    productsPanel: {
      border: '1px solid #F6F8FB',
      borderRadius: 8,
      width: '100%',
    },
    productsHeader: {
      backgroundColor: '#F6F8FB',
    },
    productImage: {
      width: '10%',
      float: 'left',
      marginRight: 16,
    },
  })
);

interface IProps {
  deliveryType: deliveryType;
  deliveryDetails: IProfile;
  paymentType: payment;
  products: CartItem[];
  discountsStatus: DiscountsStatus;
  packetaInfo?: packetaInfo;
  paymentPrice: number;
  deliveryPrice: number;
  totalPrice: number;
  deliveryPaymentConfig?: ShippingPaymentConfiguration;
  presents: IProduct[];
  doNotUseHeurekaSatisfy: boolean;
  setDoNotUseHeurekaSatisfy: (newVal: boolean) => void;
  differentDelivery: boolean;
}

export default function OrderRecapitulation({
  deliveryType,
  paymentType,
  deliveryDetails,
  products,
  discountsStatus,
  packetaInfo,
  deliveryPrice,
  paymentPrice,
  deliveryPaymentConfig,
  totalPrice,
  presents,
  doNotUseHeurekaSatisfy,
  setDoNotUseHeurekaSatisfy,
  differentDelivery,
}: IProps) {
  const classes = useStyles();
  const layout = useLayout();

  const getTotalProductPrice = (ammount: number, p: IProduct) =>
    (ammount - discountsStatus.getNumberOfFreeUnits(p)) * getPrice(p, layout.homeConfig.todaysSpecial) -
    discountsStatus.getAdditionalDiscountForProduct(p);

  const buyOnCompany = deliveryDetails.address?.company || deliveryDetails.vat || deliveryDetails.companyId;

  return (
    <>
      <EText variant="h6">Objednané produkty</EText>
      <br />
      <br />
      <div className={classes.productsPanel}>
        <Table>
          <TableHead>
            <TableRow className={classes.productsHeader}>
              <TableCell>
                <EText>Produkt</EText>
              </TableCell>
              <Hidden xsDown>
                <TableCell align="center">
                  <EText>Cena&nbsp;za&nbsp;kus</EText>
                </TableCell>
              </Hidden>
              <TableCell align="center">
                <EText>Množství</EText>
              </TableCell>
              <TableCell align="center">
                <EText>Celková&nbsp;cena</EText>
              </TableCell>
            </TableRow>
            {products.map(({ product: p, amount }) => (
              <TableRow key={p.id}>
                <TableCell>
                  <Hidden smDown>
                    <img className={classes.productImage} src={(p.images || [''])[0]} alt="product preview" />
                  </Hidden>
                  <EText color="secondary" variant="h6">
                    {p.title}
                  </EText>
                  <EText color="primary">{p.subtitle}</EText>
                  <Hidden smDown>
                    <EText color="primary" variant="caption">
                      {p.shortDescription}
                    </EText>
                  </Hidden>
                </TableCell>
                <Hidden xsDown>
                  <TableCell align="center">
                    <EText color="primary" variant="h6">
                      {separateThousands(getPrice(p, layout.homeConfig.todaysSpecial))} Kč
                    </EText>
                    {discountsStatus.getNumberOfFreeUnits(p) > 0 && (
                      <EText color="secondary" variant="caption">
                        {discountsStatus.getNumberOfFreeUnits(p)}
                        x&nbsp;Zdarma&nbsp;(1&nbsp;Kč)
                      </EText>
                    )}
                    {p.secondItemDiscount && p.secondItemDiscount.discount && amount > 1 && (
                      <EText color="secondary" variant="caption">
                        Každý 2. kus o {p.secondItemDiscount.discount.value}{' '}
                        {p.secondItemDiscount.discount.factor === 'absolute' ? ' Kč' : '%'} levněji
                      </EText>
                    )}
                  </TableCell>
                </Hidden>
                <TableCell align="center">
                  <EText>{amount}</EText>
                </TableCell>
                <TableCell align="center">
                  <EText color="primary" variant="h6">
                    {separateThousands(getTotalProductPrice(amount, p))} Kč
                  </EText>
                </TableCell>
              </TableRow>
            ))}
          </TableHead>
        </Table>
      </div>
      {discountsStatus.getPresents().length > 0 && presents.length > 0 && (
        <Gifts
          presents={discountsStatus.getPresents().map((p) => {
            return {
              present: presents.filter((pr) => pr.id === p.presentId)[0],
              ammount: p.ammount,
            };
          })}
        />
      )}
      <br />
      <br />
      <br />
      <Grid container>
        <Grid item xs={12} md={6}>
          <EText variant="h6">Doprava</EText>
          <EText>
            {deliveryType && deliveryPaymentConfig?.shippingPrices[deliveryType]?.label}{' '}
            {deliveryPrice > 0 ? `(${deliveryPrice} Kč)` : '(zdarma)'}
            {deliveryType === 'personalPickup' ? `: ${packetaInfo?.name}` : ''}
          </EText>
          {deliveryType === 'packeta' && (
            <>
              <br />
              <EText>
                Bliší informace o výdejním místě naleznete na odkaze:{' '}
                <a href={packetaInfo?.url} target="_blank" rel="noopener noreferrer">
                  {packetaInfo?.url}
                </a>
              </EText>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <EText variant="h6">Platba</EText>
          <EText>
            {paymentType && deliveryPaymentConfig?.paymentPrices[paymentType]?.label}{' '}
            {paymentPrice > 0 ? `(${paymentPrice} Kč)` : '(zdarma)'}
          </EText>
        </Grid>
      </Grid>
      <br />
      <br />
      <br />
      <EText variant="h6">Fakturační a dodací adresa</EText>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12} md={differentDelivery ? 6 : 12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>Fakturační adresa</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell>
                  <b>Jméno</b>
                </TableCell>
                <TableCell>
                  {deliveryDetails.address?.name} {deliveryDetails.address?.surname}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Adresa</b>
                </TableCell>
                <TableCell>
                  {deliveryDetails.address?.street} {deliveryDetails.address?.houseNum}, {deliveryDetails.address?.zip}{' '}
                  {deliveryDetails.address?.city}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Email</b>
                </TableCell>
                <TableCell>{deliveryDetails.email}</TableCell>
              </TableRow>
              {deliveryDetails.address?.phone && (
                <TableRow>
                  <TableCell>
                    <b>Telefon</b>
                  </TableCell>
                  <TableCell>{formatPhoneNumber(deliveryDetails.address?.phone || '')}</TableCell>
                </TableRow>
              )}
              {deliveryDetails.deliveryAddress?.phone && (
                <TableRow>
                  <TableCell>
                    <b>Telefon pro kurýra</b>
                  </TableCell>
                  <TableCell>{formatPhoneNumber(deliveryDetails.deliveryAddress?.phone || '')}</TableCell>
                </TableRow>
              )}
              {deliveryDetails.deliveryAddress?.company && (
                <TableRow>
                  <TableCell>
                    <b>Firma</b>
                  </TableCell>
                  <TableCell>{deliveryDetails.deliveryAddress?.company}</TableCell>
                </TableRow>
              )}
              {buyOnCompany && (
                <>
                  <TableRow>
                    <TableCell>
                      <b>Firma</b>
                    </TableCell>
                    <TableCell>{deliveryDetails.address?.company}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>IČO</b>
                    </TableCell>
                    <TableCell>{deliveryDetails.companyId}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>DIČ</b>
                    </TableCell>
                    <TableCell>{deliveryDetails.vat}</TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </Grid>
        {differentDelivery && (
          <Grid item xs={12} md={6}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>Dodací adresa</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Jméno</b>
                  </TableCell>
                  <TableCell>
                    {deliveryDetails.deliveryAddress?.name} {deliveryDetails.deliveryAddress?.surname}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Adresa</b>
                  </TableCell>
                  <TableCell>
                    {deliveryDetails.deliveryAddress?.street} {deliveryDetails.deliveryAddress?.houseNum},{' '}
                    {deliveryDetails.deliveryAddress?.zip} {deliveryDetails.deliveryAddress?.city}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        )}
      </Grid>
      <br />
      <br />
      <EText variant="h6" align="right">
        Celková cena s DPH&nbsp;&nbsp;&nbsp;&nbsp;
        {separateThousands(totalPrice)} Kč
      </EText>
      <br />
      <br />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Checkbox
          checked={doNotUseHeurekaSatisfy}
          onClick={() => setDoNotUseHeurekaSatisfy(!doNotUseHeurekaSatisfy)}
          color="primary"
        />{' '}
        <EText inline>
          Nesouhlasím se zasláním dotazníku spokojenosti v rámci programu Ověřeno zákazníky, který pomáhá zlepšovat naše
          služby.
        </EText>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Checkbox defaultChecked color="primary" />{' '}
        <EText inline>
          Přeji si a souhlasím se zasíláním akčních nabídek a dalších informací od {Environment.fullName2ndForm}.
        </EText>
      </div>
      <EText align="right">Odesláním objednávky souhlasíte s Všeobecnými obchodními podmínkami.</EText>
      <br />
    </>
  );
}
